import { configureStore } from '@reduxjs/toolkit';
import userSlice from './userSlice';
import apiReducer from './apiSlice';
import userdataReducer from './controller';

const store = configureStore({
  reducer: {
    data: userSlice,
    api: apiReducer,
    userdata: userdataReducer,
  },
});

export default store;
