import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    userdata: [],
    // Notification_count:0
};

const userSlice = createSlice({
  name: 'userdata',
  initialState,
  reducers: {
    LoginSucess: (state, action) => {
      state.userdata.push(action.payload);
    },
    // NotificationCount:(state, action)=>{
    //   state.Notification_count = action.payload;
    // }
    
  },
});

export const {LoginSucess } = userSlice.actions;
export default userSlice.reducer;
