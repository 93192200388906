import { Navigate, Outlet } from "react-router";
import auth from './components/Auth';

const useAuth = () => {
  return auth.isLogin();
};

const AuthenticatedRoute = () => {
  const isAuth = useAuth();
  // console.log(isAuth);
  return isAuth ? <Outlet /> : <Navigate to="/" />;
};
export default AuthenticatedRoute;