import React, { lazy, Suspense, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./components/style/screens.css";
import "./components/common/Provider.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import Loader from "./components/common/Loader";
import { toastify, userlogout } from "./redux/controller";
import AuthenticatedRoute from "./AuthenticatedRoute.js";
import UnAuthenticatedRoute from "./UnAthenticatedRoute.js";
import { useSelector } from "react-redux";

const Login = lazy(() => import("./components/Screens/Login.jsx"));
const NotFound = lazy(() => import("./components/Screens/NotFound"));
const ForgotPassword = lazy(() =>
  import("./components/Screens/ForgotPassword")
);
const MyProfile = lazy(() => import("./components/Screens/MyProfile"));
const Dashboard = lazy(() => import("./components/pages/Dashboard"));
const ChangePassword = lazy(() =>
  import("./components/Screens/ChangePassword")
);
const Settings = lazy(() => import("./components/pages/Settings"));
const MyTeam = lazy(() => import("./components/pages/MyTeam"));

const ProvidersView = lazy(() =>
  import("./components/pages/ProviderPage/ProvidersView")
);
const TestResults = lazy(() => import("./components/pages/TestResults"));
const Practices = lazy(() => import("./components/pages/Practices"));

const Patient = lazy(() => import("./components/pages/Patients/Patient"));
const PatientsViewDetails = lazy(() =>
  import("./components/pages/Patients/PatientsViewDetails")
);
const TestKitRequests = lazy(() =>
  import("./components/pages/OrdersPage/TestKitRequests")
);
const Prescriptions = lazy(() =>
  import("./components/pages/OrdersPage/Prescriptions")
);
const PreScriptionsViewDetails = lazy(() =>
  import("./components/pages/OrdersPage/PreScriptionsViewDetails")
);
const SubProviderViewDetails = lazy(() =>
  import("./components/pages/ProviderPage/SubProviderViewDetails")
);
const ChatProvider = lazy(() => import("./components/pages/Chat/ChatProvider"));
const SignUp = lazy(() => import("./components/Screens/SignUp"));
const AddnewPatient = lazy(() =>
  import("./components/pages/Patients/AddNewPatients")
);
const PractitionerInformation = lazy(() =>
  import("./components/PractitionerIn formation/PractitionerInformation")
);
const Demo = lazy(() => import("./components/Demo"));
const DashboardView = lazy(() =>
  import("./components/pages/Provider Dashboard View/DashboardView")
);
const RecoverPassword = lazy(() =>
  import("./components/Screens/RecoverPassword.js")
);
const PharmacySignup = lazy(() =>
  import("./components/Screens/PharmacySignup.js")
);

function App() {
  const userdata = useSelector((state) => state.userdata.userdata);
  const usertoken = useSelector((state) => state.userdata.usertoken);

  const renderDashboardOrRedirect = () => {
    if (userdata && userdata.role === "Provider") {
      return userdata.agreement_status ? (
        <Dashboard />
      ) : (
        <Navigate to="/practitioner_information" />
      );
    } else {
      return <Dashboard />;
    }
    return <Navigate to="/dashboard" />;
  };

  return (
    <Suspense
      fallback={
        <div>
          <Loader />
        </div>
      }
    >
      <Router>
        <Routes>
          <Route element={<UnAuthenticatedRoute Routes />}>
            <Route path="/" element={<Login />} />
            <Route path="/forgot_password" element={<ForgotPassword />} />
            <Route path="/recover_password" element={<RecoverPassword />} />
          </Route>
          <Route path="/sign_up" element={<SignUp />} />
          {/* <Route path="/pharmacy_signup" element={<PharmacySignup />} /> */}

          <Route element={<AuthenticatedRoute Routes />}>
            <Route path="/dashboard" element={renderDashboardOrRedirect()} />
            <Route path="/my_profile" element={<MyProfile />} />
            <Route path="/change_password" element={<ChangePassword />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/my_team" element={<MyTeam />} />

            <Route path="/test_results" element={<TestResults />} />

            <Route path="/patient" element={<Patient />} />
            <Route
              path="/patients_view_details"
              element={<PatientsViewDetails />}
            />
            <Route path="/test_kit_requests" element={<TestKitRequests />} />
            <Route path="/prescriptions" element={<Prescriptions />} />
            <Route
              path="/PreScriptions_view_details"
              element={<PreScriptionsViewDetails />}
            />

            <Route path="/Chat_provider" element={<ChatProvider />} />
            <Route path="/add_new_patient" element={<AddnewPatient />} />
            <Route path="/dash_board_view" element={<DashboardView />} />

            {/* Render PractitionerInformation if agreement_status is false */}
            <Route
              path="/practitioner_information"
              element={
                userdata &&
                userdata.role === "Provider" &&
                userdata.agreement_status === false ? (
                  <PractitionerInformation />
                ) : (
                  <Navigate to="/dashboard" />
                )
              }
            />

            {userdata &&
              (userdata.role === "Pharmacy Solutions" ||
                userdata.role === "GHMD") && (
                <>
                  <Route path="/providers_view" element={<ProvidersView />} />
                  <Route path="/practices" element={<Practices />} />
                  <Route
                    path="/sub_provider_view_details"
                    element={<SubProviderViewDetails />}
                  />
                </>
              )}
          </Route>

          {/* <Route path="/" element={<Demo />} /> */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </Suspense>
  );
}

export default App;
