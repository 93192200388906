function isLogin() {
  return localStorage.getItem("userdata") ? true : false;
}

function logout() {
  localStorage.clear();
}
module.exports = {
  isLogin: isLogin,
  logout: logout,
};
