import { Navigate, Outlet } from "react-router";
import auth from './components/Auth';

const useAuth = () => {
  return auth.isLogin();
};


const UnuthenticatedRoute =() => {
    
  const isAuth =   useAuth();
  return !isAuth ? <Outlet /> : <Navigate to="/dashboard" />;
};
export default UnuthenticatedRoute;