import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toastify, userlogout } from "./controller";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

// Define the initial state
const initialState = {
  data: [],
  loading: false,
  error: null,
};

// Define a thunk for fetching data asynchronously
export const fetchData = createAsyncThunk('api/fetchData', async (body) => {

  try {
    var headers = body.headers
    var apiurl = body.apiurl
    var method = body.method
    delete body.headers
    delete body.apiurl
    delete body.method
    if(method == 'post'){
      const response = await axios.post(process.env.REACT_APP_API_URL+apiurl, body, {headers: headers});
      return response.data;
    }
    else if(method == 'put'){
      const response = await axios.put(process.env.REACT_APP_API_URL+apiurl, body, {headers: headers});
      return response.data;
    }
    else{
      const response = await axios.get(process.env.REACT_APP_API_URL+apiurl, {headers: headers});
      return response.data;
    }
  } catch (error) {
    console.log('error')
    console.log(error.response.data.message == 'Token expired or invalid token provided!')
    localStorage.clear();
    if(error.response.data.status == 500 && error.response.data.message == 'Token expired or invalid token provided!'){
      localStorage.clear();
      window.location.reload();
      console.log("Logout")
      console.log(error.response.data)
    }
    return error
  }
});

// Define the slice
const apiSlice = createSlice({
  name: 'api',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
  },
});

export default apiSlice.reducer;