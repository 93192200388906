import { createSlice } from '@reduxjs/toolkit'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

var initialState = {
  usertoken: "",
  userdata: {},
  Notification_count: 0,
  store: ""
}
if(localStorage.getItem('userdata')){
  // console.log("localStorage.getItem('token')")
  // console.log(localStorage.getItem('userdata'))
  initialState.usertoken = JSON.parse(localStorage.getItem('userdata')).token
  initialState.store = JSON.parse(localStorage.getItem('userdata')).role
  initialState.userdata = JSON.parse(localStorage.getItem('userdata'))
}

// if (localStorage.getItem('userdata')) {
//   const userdata = localStorage.getItem('userdata');
  
//   try {
//     const parsedData = JSON.parse(userdata);
//     initialState.usertoken = parsedData.token;
//     initialState.userdata = parsedData;
//   } catch (error) {
//     console.error("Error parsing userdata:", error);
//     // Optionally, clear the localStorage if the data is corrupted
//     localStorage.removeItem('userdata');
//   }
// }

export const userdataSlice = createSlice({
  name: 'userdata',
  initialState,
  reducers: {
    userlogin: (state, params) => {
      if(params.payload.token){
        state.usertoken = params.payload.token
        localStorage.setItem("usertoken", params.payload.token)
      }
      state.userdata = params.payload
      state.store = params.payload
      localStorage.setItem("userdata", JSON.stringify(params.payload))
      localStorage.setItem("store", params.payload.data.role)
    },
    userlogout: (state) => {
      state.usertoken = ""
      state.userdata = {}
    },
    Notification_count: (state, params) => {
      // console.log(params.payload)
      state.Notification_count = params.payload
    }
  },
})

export const toastify = async (message) => {

  const toastId = toast.info(message.message,{
    toastId: 'success1',
    icon: false,
    autoClose: 5000});
};

export const clearuser = async (navigate) => {
  localStorage.removeItem("userdata")
  localStorage.removeItem("store")
  localStorage.removeItem("count_notification_unread")
  localStorage.removeItem("token")
  if(navigate){
    navigate('/')
  }
};
export const makeid = async (length) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};

// Action creators are generated for each case reducer function
export const { userlogin, userlogout, Notification_count } = userdataSlice.actions

export default userdataSlice.reducer